<template>
  <div>
    <Buttons />
    <Icons />
    <Notifications/>
    <Tabs/>
    <Typography/>
  </div>
</template>

<script>
import Icons from "./component/Icons.vue";
import Buttons from "./component/Buttons.vue";
import Notifications from "./component/Notifications.vue";
import Tabs from "./component/Tabs.vue";
import Typography from "./component/Typography.vue";

export default {
  name: "HRvecIcon",
  components: { Icons, Buttons,Notifications,Tabs,Typography },
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>
